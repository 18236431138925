import React, { Component } from "react";
import { getAllcars, ontapModel } from "../method/all_car";
import { ErrorPage, LoadingPage } from "../widget/warning_page";
import { getCarImage } from "../module/api_init";
import { getComaPrice, makespaceline } from "../module/simple";
import { EnquiryPopup } from "../widget/popups";
import HomeFooter from "../widget/home_footer";
import { Header } from "../widget/home_top";
import "../style/ac1.css";

export default class AllCars extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      brands: [],
      brand: 0,
      locations_h: [],
      locations_b: [],
      locations_m: [],
      locations_r: [],
      location: {},
      enquiryPopup: null,
      selectedmodel: null,
      dynamic_links: [],
    };
  }

  componentDidMount() {
    getAllcars(this);
  }

  render() {
    const selctedTypeStyle = { color: "#00afa0", fontWeight: "bold" };
    const { loading, error, items, brand, brands, selectedmodel, location } =
      this.state;

    if (loading) return <LoadingPage />;
    if (error) return <ErrorPage />;
    return (
      <React.StrictMode>
        <Header props={this} />
        <div className="hb_1a">
          <div className="hb_1d" />
          <div className="hb_1b_a">
            {selectedmodel ?? "Maruti Suzuki - All cars"}
          </div>
          <div className="hb_1b1">
            <div className="hb_1b2">
              {selectedmodel === null
                ? brands.map((it, k) => (
                    <div
                      className="ac1_g"
                      key={k}
                      onClick={() => this.setState({ brand: k })}
                    >
                      <div
                        style={k === brand ? selctedTypeStyle : {}}
                        className="ac1_c"
                      >
                        {it.title}
                      </div>
                      {brand === k ? <div className="ac1_a" /> : null}
                    </div>
                  ))
                : null}
            </div>
            <div className="hb_1b2b" />
            <div className="ac1_h">
              {selectedmodel === null
                ? brands[brand].models.map((it, k) => (
                    <div
                      key={k}
                      className="ac1_d"
                      onClick={() => ontapModel(this, it.title)}
                    >
                      <img
                        className="ac1_e"
                        src={getCarImage(it.image)}
                        alt="Kalyanimotors Leading Maruti Suzuki Dealership"
                      />
                      <div className="ac1_f">{it.title}</div>
                    </div>
                  ))
                : null}

              {selectedmodel !== null
                ? items[0].varients.map((item, k) => (
                    <SingleCarForAllCars
                      key={k}
                      item={item}
                      props={this}
                      positions={k}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
        <HomeFooter props={this} />
        <EnquiryPopup props={this} />
      </React.StrictMode>
    );
  }
}

function SingleCarForAllCars({ item, props, positions }) {
  const { location } = props.state;
  let rand = Math.random() * item.products.length;
  rand = Math.floor(rand);
  if (item.products.length === 0) return null;
  return (
    <div className="hb_8c">
      <div
        className="ac1_i"
        onClick={() =>
          (window.location =
            "/car/" +
            makespaceline(item.products[rand].route) +
            "/" +
            positions)
        }
      >
        <img
          className="ac1_j"
          src={getCarImage(item.products[rand].images[0].images)}
          alt="Kalyanimotors Leading Maruti Suzuki Dealership"
        />
      </div>
      <div className="hb_8f">
        <div className="ac1_k">{item.var_title}</div>
        <div className="hb_8g">
          ₹ {getComaPrice(item.products[rand].price)}/-
        </div>
        <div
          className="hb_8h"
          onClick={() =>
            props.setState({
              enquiryPopup: { car: item.var_title, type: 5 },
            })
          }
        >
          Ex-showroom price
        </div>
        {window.innerWidth < 600 ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: 9,
                background: "#047A70",
                width: "48%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "500",
                borderRadius: 10,
                height: 20,
                marginTop: 4,
              }}
              onClick={() =>
                window.location.assign("tel:" + (location.phone_number ?? ""))
              }
            >
              Call Now
            </div>
            <div
              style={{
                fontSize: 9,
                background: "#2E3192",
                width: "48%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "500",
                borderRadius: 10,
                height: 20,
                marginTop: 4,
              }}
              onClick={() =>
                props.setState({
                  enquiryPopup: { car: item.name, type: 0 },
                })
              }
            >
              Get Best Price
            </div>
          </div>
        ) : (
          <React.StrictMode>
            <div
              className="hb_8i"
              onClick={() =>
                props.setState({
                  enquiryPopup: { car: item.var_title, type: 0 },
                })
              }
            >
              Show On-Road Price
            </div>
            <div
              className="hb_8i_1"
              onClick={() =>
                props.setState({
                  enquiryPopup: { car: item.var_title, type: 6 },
                })
              }
            >
              Get Best Offers
            </div>
          </React.StrictMode>
        )}
      </div>
    </div>
  );
}
