import React, { useState } from 'react';
import '../style/Compare_Car.css';
import SelectCar from './SelectCar';
import plusImg from '../asset/plus.png';

import plusImg1 from '../asset/test/gym-01.png';
import plusImg2 from '../asset/test/KIds play area-01.png';
import plusImg3 from '../asset/test/library-01.png';
import plusImg4 from '../asset/test/Metro.png';
import plusImg5 from '../asset/test/park-01.png';
import plusImg6 from '../asset/test/pet friendly-01.png';
import plusImg7 from '../asset/test/retail centre-01.png';
import plusImg8 from '../asset/test/school-01.png';
import plusImg9 from '../asset/test/shuttle court.png';
import plusImg10 from '../asset/test/skating area-01.png';
import plusImg11 from '../asset/test/yoga-01.png';

function Compare_Car({ props }) {
	console.log(props);
	const [value, setValue] = useState(false);

	// const toggleValue = () => {
	// 	if (value === false) {
	// 		setValue(true);
	// 	} else {
	// 		setValue(false);
	// 	}
	// };

	return (
		<div className="hb_1a selectCar" id="hm_c">
			<div className="hb_1d" />
			<div className="hb_1b headingCont">
				<div className="hb_1b_a"> Compare Car </div>
			</div>
			<div className="hb_1b"></div>
			<div
				className="hb_1c compareCarDesc"
				style={{ textAlign: 'justify', color: 'grey' }}
			>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis,
				temporibus eius inventore repellat, error fugiat, distinctio maiores
				assumenda debitis modi voluptatum odio fuga? Suscipit totam cum nisi
				excepturi adipisci vitae consequuntur magni libero cumque quam aut
				officia labore odit vero illum itaque, mollitia, dolores, consequatur
				eligendi velit voluptas. Omnis et hic excepturi dignissimos deserunt
				exercitationem rem sunt necessitatibus recusandae. Eligendi velit animi
				explicabo, sequi impedit quam debitis dolore ratione est ab, sapiente
				nam nulla ex error deserunt? Est, mollitia illum veritatis, aliquam,
				officia sed consequuntur quibusdam eligendi at autem vero! Sint,
				perspiciatis temporibus? Nostrum voluptatibus totam officia modi dolores
				nemo.
			</div>
			{/* <div className="compare_car"></div> */}

			{/* icon demo section */}
			{/* <>
				<div
					className="div"
					style={{
						margin: '1rem  13rem 13rem 13rem',
						display: 'flex',
						gap: '20rem',
					}}
				>
					<img src={plusImg1} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
					<img src={plusImg2} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
					<img src={plusImg3} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
				</div>
				<div
					className="div"
					style={{
						margin: '1rem  13rem 13rem 13rem',
						display: 'flex',
						gap: '20rem',
					}}
				>
					<img src={plusImg5} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
					<img src={plusImg6} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
					<img src={plusImg7} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
				</div>
				<div
					className="div"
					style={{
						margin: '1rem  13rem 13rem 13rem',
						display: 'flex',
						gap: '20rem',
					}}
				>
					<img src={plusImg9} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
					<img src={plusImg10} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
					<img src={plusImg11} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
				</div>
				<div
					className="div"
					style={{
						margin: '1rem  13rem 13rem 13rem',
						display: 'flex',
						gap: '20rem',
					}}
				>
					<img src={plusImg8} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
					<img src={plusImg4} alt="Kalyanimotors Leading Maruti Suzuki Dealership" width={70} height={70} />
				</div>
			</> */}

			{/* icon demo section ends here */}

			<div className="compareCont">
				<div className="imgcont" onClick={() => setValue(!value)}>
					<img
						src={plusImg}
						alt="Kalyanimotors Leading Maruti Suzuki Dealership"
						width={100}
						height={100}
					/>
				</div>
				<div class={value ? 'modal' : 'hideModal'}>
					<div class="modal-content">
						<span class="close" onClick={() => setValue(!value)}>
							&times;
						</span>
						{/* select car section */}
						<div className="modal-content-data">
							<div className="ChooseCarCont">
								<SelectCar props={props} />
								<img
									src="https://img.lovepik.com/element/40075/2395.png_860.png"
									alt="Kalyani Motors Leading Maruti Suzuki Dealership"
									height="20px"
									width="20px"
								/>
								<SelectCar props={props} />
							</div>
						</div>
						<div
							className="compareBtn"
							style={{
								// margin: ' 4rem 0 0 16rem',
								position: 'relative',
								top: '15%',
								left: '40%',
								height: '2rem',
								width: '6rem',
								// backgroundColor: 'linear-gradient(#fd3672, #ea0d48)',
								backgroundColor: '#ea0d48',
								border: 'none',
								padding: '5px',
								borderRadius: '5px',
								cursor: 'pointer',
								textAlign: 'center',
							}}
						>
							COMPARE
						</div>
						{/* select car section ends here */}
					</div>
				</div>
				<div className="selectButton">Select Cars</div>
			</div>
		</div>
	);
}

export default Compare_Car;
