import React from 'react';
import { getImage } from '../module/api_init';
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';

function ServiceBanner({ props }) {
	const { banners, location } = props.state;
	if (location === null) return '';
	const _banners =
		location.title.toLowerCase() === 'hyderabad'
			? banners.hyderabad
			: banners.hyderabad;

	return (
		<div className="hm1_a">
			<div className="hm1_b">
				<Carousel
					autoPlay={true}
					infiniteLoop={true}
					showArrows={true}
					showThumbs={false}
				>
					{_banners.map((b, k) => {
						return (
							<div key={k}>
								{/* {console.log(b.banner_img.split('.').pop() === 'jpg')} */}
								{/* {console.log(b)}
								{console.log(b.banner_img)} */}
								{b.banner_img.split('.').pop() === 'jpg' ? (
									<img
										alt="Kalyani Motors Leading Maruti Suzuki Dealership"
										className="hm1_d"
										src={getImage(b.banner_img)}
									/>
								) : (
									<ReactPlayer
										url={getImage(b.banner_img)}
										loop={true}
										height="100%"
										width="100%"
										playing={true}
									/>
								)}
							</div>
						);
					})}
				</Carousel>
			</div>
		</div>
	);
}

export default ServiceBanner;
