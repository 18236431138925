import React, { useState } from "react";
import checkAnim from "../asset/checkAnim.gif";
import tickIcon from "../asset/check.JPG";
import "../style/hm3.css";
import enquiry from "../method/enquiry";
import { getImage } from "../module/api_init";

function HomeonroadPrice2({ props }) {
  const [booked, setbooked] = useState(0);
  const [bike, setbike] = useState("");

  const { seasonCars, bannar_p } = props.state;

  const detail = [
    { name: "Book A Test Drive" },
    { name: "Book Car Evaluation" },
    { name: "Book A Home Visit" },
    { name: "Book A Showroom Visit" },
  ];
  return (
		<div className="center">
			<div
				className="hm3_Container2"
				style={{ backgroundImage: 'url(' + getImage(bannar_p) + ')' }}
			>
				<div className="hm3_sf_d" style={{ width: '450px' }}>
					.
				</div>
				<div className="hm3_1_left-div">
					{booked === 0 ? (
						<>
							<div className="all1_hm_title">Enquiry</div>
							<div
								style={{
									fontSize: 11,
									color: 'gray',
									padding: '0px 10px',
								}}
							>
								Kindly fill your details to get desired services from Kalyani
								Motors sales expert.
							</div>
						</>
					) : (
						<></>
					)}

					{booked !== 0 ? (
						booked === 1 ? (
							<img
								alt="Kalyani Motors Leading Maruti Suzuki Dealership"
								style={{ padding: '20px 103px', width: 100 }}
								src={checkAnim}
							/>
						) : (
							<img
								alt="Kalyani Motors Leading Maruti Suzuki Dealership"
								style={{ padding: '20px 103px', width: 100 }}
								src={tickIcon}
							/>
						)
					) : (
						<form
							id="booking_form1"
							onSubmit={async (e) => {
								if (e.target.id.value.length !== 10) {
									alert('Not a valid phone number');
									return;
								}
								setbooked(1);
								setbike(e.target.car.value);
								const body = {
									name: e.target.name.value,
									phone: e.target.id.value,
									// email: "",
									location: props.state.location.address,
									// vehicle: e.target.car.value,
									purpose: e.target.car.value,
								};
								await enquiry(body);
								setTimeout(() => setbooked(2), 1700);
							}}
						>
							<input
								placeholder="Enter Your Name"
								className="hm3_input_tab"
								type="text"
								id="name"
								required
							/>
							<input
								placeholder="Enter Your Phone Number"
								className="hm3_input_tab"
								type="text"
								id="id"
								required
							/>
							{/* <select className="hm3_input_tab" id="car" required>
                {seasonCars.map((p) =>
                  p.bikes.map((bike, k) => (
                    <option value={bike.name} key={k}>
                      {bike.name}
                    </option>
                  ))
                )}
                <option>Others</option>
              </select> */}
							{
								<select className="hm3_input_tab" id="car" required>
									<option value="Book A Test Drive">Book A Test Drive</option>
									<option value="Book Car Evaluation">
										Book Car Evaluation
									</option>
									<option value="Book A Home Visit">Book A Home Visit</option>
									<option value="Book A Showroom Visit">
										Book A Showroom Visit
									</option>
								</select>
							}
							<button type="submit" className="hm3_btn">
								SUBMIT
							</button>
						</form>
					)}
					{booked !== 0 ? (
						<p
							style={{
								padding: '0px 4px',
								fontSize: 14,
								color: '#4d5057',
								textAlign: 'center',
								fontWeight: 'bold',
							}}
						>
							Thank you for your interest in {bike} We will get in touch with
							you soon...
						</p>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
}

export default HomeonroadPrice2;
