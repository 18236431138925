import React, { Component } from 'react';
import '../style/cu1.css';
import directIcon from '../asset/direct_icon.png';
import phoneIconB from '../asset/phone_icon_b.png';
import { EnquiryPopup } from '../widget/popups';
import { getdataforContact } from '../method/contact';
import { ErrorPage, LoadingPage } from '../widget/warning_page';
import { Header } from '../widget/home_top';
import HomeFooter from '../widget/home_footer';
import HomeQuestions from '../widget/home_questions';
import { getImage } from '../module/api_init';

export default class ContactPage extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			enquiryPopup: null,
			locations_h: [],
			locations_b: [],
			locations_m: [],
			locations_r: [],
			location: {},
			faq: [],
			shops: [],
			area: 0,
			brand: 0,
			type: 0,
			dynamic_links: [],
		};
	}

	componentDidMount() {
		getdataforContact(this);
		// this.checklocation();
	}
	// checklocation = () => {
	// 	console.log('window location', window.location.pathname.split('/'));
	// 	let array = window.location.pathname.split('/');
	// 	console.log(array[array.length - 1].toUpperCase());
	// 	let shops = ['Bangalore', 'Hyderabad', 'Mysore'];
	// 	let indexnumber = shops.findIndex((it) => {
	// 		return it.toUpperCase() === array[array.length - 1].toUpperCase();
	// 	});
	// 	if (indexnumber != -1) {
	// 		this.setState({ area: indexnumber });
	// 	}
	// };

	render() {
		const { loading, error, area, brand, type, shops } = this.state;
		
		if (loading) return <LoadingPage />;
		if (error != null) return <ErrorPage />;
		return (
			<React.StrictMode>
				<Header props={this} />
				<div className="cu1_a_a">
					<div className="cu1_a_b">
						<div className="cu1_a_c">
							{shops.map((data, k) => (
								<div
									key={k}
									className="cu1_a_d"
									onClick={() => this.setState({ area: k })}
								>
									<div className="cu1_a_e">{data.title.toUpperCase()}</div>
									{area === k ? <div className="cu1_a_f" /> : null}
								</div>
							))}
						</div>
						<div className="cu1_a_g" />
						<div className="cu1_a_c">
							{shops[area].data
								.sort((a, b) => {
									const order = ['Arena', 'Nexa', 'Truevalue'];
									return order.indexOf(a.title) - order.indexOf(b.title);
								})
								.map((data, k) => (
									<div
										key={k}
										className="cu1_a_d"
										onClick={() => this.setState({ brand: k })}
									>
										<div className="cu1_a_e">{data.title}</div>
										{brand === k ? <div className="cu1_a_f" /> : null}
									</div>
								))}
						</div>

						<div className="cu1_a_g" />
						<div className="cu1_a_c">
							{shops[area].data[brand].data.map((data, k) => (
								<div
									key={k}
									className="cu1_a_d"
									onClick={() => this.setState({ type: k })}
								>
									<div className="cu1_a_e">{data.title}</div>
									{type === k ? <div className="cu1_a_f" /> : null}
								</div>
							))}
						</div>
						<div className="cu1_a_g" />
					</div>
				</div>
				{shops[area].data[brand].data[type].data.map((loc, k) => (
					<div className="cu1_b_a" key={k}>
						<div className="cu1_b_lc1_a">
							<div className="cu1_b_lc1_ab">
								<img
									alt="Location"
									className="cu1_b_lc1_ab"
									src={getImage(loc.image)}
								/>
							</div>
							<div className="cu1_b_lc1_ac">
								<div className="cu1_b_lc1_acba">{loc.location_name}</div>
								<div className="cu1_b_lc1_acbb">{loc.address}</div>
								<div className="cu1_b_lc1_acbba">
									{loc.phone}/{loc.alternate_phone}
								</div>
								<div className="cu1_b_lc1_acbba">
									customersupport@kalyanimotors.com
								</div>
								<div className="cu1_b_lc1_acbba">
									Opening Time {loc.open_time}&nbsp;&nbsp;&nbsp;&nbsp;Closing
									Time {loc.close_time}
								</div>

								<div className="cu1_b_lc1_aca">
									<div
										className="cu1_b_lc1_acaa"
										onClick={() =>
											window.open(
												`https://maps.google.com/?q=${loc.latitude},${loc.longtitude}`
											)
										}
									>
										<img
											alt="Kalyani Motors Leading Maruti Suzuki Dealership"
											className="cu1_b_lc1_acab"
											src={directIcon}
										/>
										Directions
									</div>
									<div
										className="cu1_b_lc1_acaa"
										onClick={() => window.location.assign(`tel:${loc.phone}`)}
									>
										<img
											alt="Kalyani Motors Leading Maruti Suzuki Dealership"
											className="cu1_b_lc1_acab"
											src={phoneIconB}
										/>
										Call
									</div>
									{/* <div
            className="cu1_b_lc1_acaa"
            onClick={() => window.location.assign(loc.website)}
          >
            <img
              alt="Kalyani Motors Leading Maruti Suzuki Dealership"
              className="cu1_b_lc1_acab"
              src={globeIcon}
            />
            Web Site
          </div> */}
									<div className="cu1_b_lc1_acac">
										{/* <div
                      className="cu1_b_lc1_acaca"
                      onClick={() =>
                        this.setState({
                          enquiryPopup: {
                            location: loc.location_name,
                            type: 3,
                          },
                        })
                      }
                    >
                      Book a Test Drive
                    </div>
                    <div
                      className="cu1_b_lc1_acaca"
                      onClick={() =>
                        this.setState({
                          enquiryPopup: {
                            location: loc.location_name,
                            type: 8,
                          },
                        })
                      }
                    >
                      Book a Service
                    </div> */}
										<div
											className="cu1_b_lc1_acaca"
											onClick={() =>
												this.setState({
													enquiryPopup: {
														location: loc.location_name,
														type: 5,
													},
												})
											}
										>
											<div style={{ marginTop: '7px' }}> Contact Us</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}

				<HomeQuestions props={this} />
				<HomeFooter props={this} />
				<EnquiryPopup props={this} />
			</React.StrictMode>
		);
	}
}
