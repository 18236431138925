///////////////////////////////////////////////////////////////////////
//  Go mechanic clone Design for kalyani moters
//  Design
//  SOURAV
//  30 aug 2021
///////////////////////////////////////////////////////////////////////

import { getServiceImage } from "../module/api_init";
import { makespaceline } from "../module/simple";

function ServiceBodyHeader({ props }) {
  const { services } = props.state;
  return (
    <div className="service_body_header">
      <div className="service_body_header_container">
        <div className="home_body_header_maincontent">
          {services.map((item, k) => (
            <div
              key={k}
              className="service_a_a"
              onClick={() =>
                (window.location = "/service/" + makespaceline(item.title))
              }
            >
              <img
                className="service_a_b"
                src={getServiceImage(item.image)}
                alt="Kalyanimotors Leading Maruti Suzuki Dealership"
              />
              <div className="service_a_c">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServiceBodyHeader;
